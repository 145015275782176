import React, { useEffect } from 'react'
import styles from './style.module.scss'
import IconoGraphy from '@/components/Common/IconoGraphy'
import { useClickOutsideListenerRef } from '@/utils/useClickOutsideListener'
import MobileBackHeader from '../MobileBackHeader'
import { useMediaQuery } from 'react-responsive'

function Popup({
  children,
  title,
  onClose,
  currentScreen,
  id = '',
  hideCloseButton = false,
  isFullHeight = false,
  customTitleClass = '',
  isZeroPadding = false,
  customClass = '',
  isFullPopup = false,
  isFullWidth = false,
  isSpaceOutside = false,
  showBack,
  isInnerPopup,
  customModalBodyClass,
  customModalTitleClass,
  onBackClicked,
  isAllPropertiesFullPopup = false,
  isConfirmPopup = false,
  showDeskTopBackWithText=false,
  isLoginSuccessScreen = false,
  isCustomClass = false,
  isTCModalBody = false,
  isScrollBarClass = false,
  istncCustomClass = false,
  sadadInvoiceClose=false,
  tcCustomBodyClass,
  isRentalDetails = false,
  positionProp,
  isDepositPopup= false,
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const rentalFontClass = isRentalDetails && !isMobile ? 'f_24' : 'f_8'
  const fontClass = isDepositPopup ? 'f_10': rentalFontClass

  const handlePopupClose = () => {
    if(currentScreen!=='login' && currentScreen!=='otp' && currentScreen!=='loginSuccess'){
    const scrollNode = document.getElementsByClassName('infinite-scroll-component')
    if(isMobile && scrollNode && scrollNode.length > 0) {
       document.getElementsByClassName('infinite-scroll-component')[0].style.overflow = 'auto';
    }
    onClose()
  }
  }
  const refPopup = useClickOutsideListenerRef(handlePopupClose)

	useEffect(() => {
		document.body.style.overflow = 'hidden'
		if(isMobile) {
			document.body.style.position ='fixed'
		}
		return () => {
			if(isInnerPopup) return;
			document.body.style.overflow = 'auto'
			document.body.style.position ='inherit'
      {positionProp && window.scrollTo({top: positionProp, behavior: 'smooth'})}
		}
	}, [])

  const goBackHandler = onBackClicked ? onBackClicked : onClose;
  const sadadPopupClassName = sadadInvoiceClose ? styles.sadad_popup_close : '';

  return (
    <div
      className={`${styles.modelOverlay} ${isInnerPopup && styles.innerPopup} `}
      id={id}
      style={isConfirmPopup ? { maxHeight: '100%' } : { maxHeight: '100vh' }}
    >
      <div
        className={`${isRentalDetails ? styles.rentalModalBox : styles.modalBox}  ${istncCustomClass && styles.tncCustomClass} ${
          isScrollBarClass && styles.scrollerBarBox
        } ${isFullHeight && styles.mCloseAuction} ${styles[customClass]} ${isFullPopup && styles.fullPopup} ${
          isAllPropertiesFullPopup && styles.allPropertiesFullPopup
        } ${isCustomClass && styles.customWidth} ${isFullWidth && styles.fullWidth} ${
          isSpaceOutside && styles.spaceOutside
        }`}
        id={`popup_modalBox`}
        ref={refPopup}
      >
        {(showBack && isMobile) || showDeskTopBackWithText ? (
          <MobileBackHeader
            title={title}
            goBack={goBackHandler}
            isLoginSuccessScreen={isLoginSuccessScreen}
            showDeskTopBackWithText={showDeskTopBackWithText}
          />
        ) : (
          <div className={`${styles.HeaderTitle} ${customModalTitleClass}  ${styles[customTitleClass]} ${isRentalDetails && styles.rentalHeaderTitle}`}>
            <h2>{title}</h2>
            {!hideCloseButton && (
              <div
                className={`${styles.crossIcon } ${sadadPopupClassName}`}
                onClick={(el) => {
                  el.stopPropagation()
                  document.body.style.overflow = 'auto'
                  onClose()
                }}
              >
                <IconoGraphy
                  iconClass={`icon-close`}
                  iconColor={'color_revamp_close_icon'}
                  fontSize={fontClass}
                ></IconoGraphy>
              </div>
            )}
          </div>
        )}
        <div
          className={`${styles.modalBody} ${isRentalDetails && styles.rentalModalBody}  ${isTCModalBody && styles.tCModalbody} ${tcCustomBodyClass && styles[tcCustomBodyClass]} ${customModalBodyClass} ${
            isAllPropertiesFullPopup && styles.allPropertiesFullPopup
          } ${isZeroPadding && styles.noSpace}`}
          id='scrollableDiv'
        >
          {children}
        </div>
      </div>
    </div>
  )
}
export default Popup
