import IconoGraphy from '@/components/Common/IconoGraphy'
import Popup from '@/hoc/components/Popup'
import { pushDataLayerEventHandler, redirectOnWhatsapp } from '@/utils/utils'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import styles from './styles.module.scss'
import { isDesktop } from 'react-device-detect'
import { getContactType } from '@/utils/constant'
import { useEffect, useState } from 'react'

const ContactUs = ({ onClose, content = null, isWallet=false}) => {
  const { t, lang } = useTranslation('translations-auction')
  const [contactDetails, setContactDetails] = useState(content)

  useEffect(() => {
    if(!content)
      fetchInfo()
  }, [])

  const fetchInfo = async() => {
    const reqHeaders = {
      'Content-Type': 'application/json',
      locale: lang,
    }
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V1}getFeatureEntity?entityGroup=support_enquiries_auction_infath`, {
      headers: reqHeaders,
    })
    const details = await response.json()
    setContactDetails(details?.data)
  }

  const handleWhatsappClick = (number) =>{
    let url = redirectOnWhatsapp(number,null,'','');
    window.open(url, isDesktop ? '_blank' : '_top')
    pushDataLayerEventHandler(window, lang, 'chat_now_clicked', 'Auction Home', { page_type:'Auction_SRP'})
  }
  //This function is used to filter contact number from backend response based on entity type 
  const getContactNumber = (entity) =>{
    return isWallet ? contactDetails?.[0]?.entity_exfield2 : contactDetails?.filter(val => val.entity_name === entity)?.[0]?.entity_exfield1
  }

  const callUs = getContactNumber(getContactType(0)) // to get number without country code
  const whatsapp = getContactNumber(getContactType(1))?.split('+')?.[1] // to get number with country code but without + 

  return (
    <Popup
      title={t('common.CONTACTUS')}
      onClose={onClose}
      isFullPopup
      customClass='stkyIn'
      customTitleClass='fHead'
      showBack={true}
    >
      <div className={styles.cBoxDetails}>
        <div className={styles.imgBoxC}>
          <Image src='/images/contact1.svg' width={311} height={207} priority={true} alt='contact' />
        </div>
        <div className={styles.contactBoxT}>
          <p className={styles.cTextPera}>{t('auctionCommon.REACH_US')}</p>
          { isWallet ? null :
             <h5 className={styles.mTitle}>{t('eWallet.CONTACT_US_SUBTITLE')}</h5>}
         { isWallet ? <a
            href={`tel: ${callUs}`}
            className={`${styles.cntBtn} ${styles.fWBtn}`}
            onClick={() =>
              pushDataLayerEventHandler(window, lang, 'call_contactus_clicked', 'Auction_SRP', { source: 'Profile' })
            }
          >
            <IconoGraphy iconClass={'icon-call'} iconColor={'color_white'} fontSize={'f_16'}></IconoGraphy>{' '}
              <span>{contactDetails?.[0]?.entity_exfield2}</span>
          </a>:
          <div className={styles.cBoxArea}>            
          <a
            href={`tel: ${callUs}`}
            className={styles.cntBtn}
            onClick={() =>
              pushDataLayerEventHandler(window, lang, 'call_contactus_clicked', 'Auction_SRP', { source: 'Profile' })
            }
          >
            <IconoGraphy iconClass={'icon-call'} iconColor={'color_white'} fontSize={'f_16'}></IconoGraphy>{' '}
              <span>{t('auctionContact.CTA_TEXT')}</span>
          </a>
          <button className={styles.wBtn} onClick={()=>handleWhatsappClick(whatsapp)}>
            <IconoGraphy iconClass={'icon-whatsapp'} iconColor={'color_white'} fontSize={'f_16'}></IconoGraphy>{' '}
            <span>{t('common.CHAT_NOW')}</span>
          </button>
          </div>}
        </div>
      </div>
    </Popup>
  )
}

export default ContactUs
