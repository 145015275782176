import React from 'react'
import styles from './styles.module.scss'

const TermNCondMain = (props) => {
  const { termData , cssContent } = props
  return (
    <>
      {termData ? (
        <div>
           <style>{cssContent}</style>
          <div className={styles.legal_stuff}>
            {/* <h6 className={styles.action_term}>{t('auctionLegal.SUBTITLE')}</h6> */}
            {/* <h2 className={styles.action_legal}>{t('auctionLegal.TITLE')}</h2> */}
          </div>
          <div className={styles.term_box}>       
            <div >
            <div dangerouslySetInnerHTML={{ __html: termData }} />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default TermNCondMain
